<template>
 <div class="container-activity">
    <div :class="is_desktop ? 'd-flex' : 'd-block'" class="justify-content-between header-activity">
      <div class="mb-8 header-first-block-activity">
        <span class="d-block ta-left openSans-100 fz-28 title-activity">My Activity</span>
        <span class="d-block ta-left openSans-300 fz-18 entries-counter-activity" :class="is_desktop ? '' : 'mt-1 mb-3'">{{ $tc('general.list.entries', questions.stats['count_questions'], { count: questions.stats['count_questions'] }) }}</span>
      </div>
      <div class="header-second-block-activity">
        <b-form-select class="document-select w-40 knowledge-qa-filter-select" v-model="filters.since" @change="getHistory">
          <b-form-select-option v-for="option in options.since" v-bind:key="option.value" :value="option.value">{{ $t(option.text) }}</b-form-select-option>
        </b-form-select>
      </div>
    </div>

    <div v-if="is_desktop" class="card col-12">
      <b-row class="justify-content-around" >
        <div class="projects-totalizers-container">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['count_questions']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Question Asked' }}</p>
        </div>
        <div class="projects-totalizers-container">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['pinned_responses']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Pinned responses' }}</p>
        </div>
        <div class="projects-totalizers-container">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['notes_saved']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Notes saved' }}</p>
        </div>
        <div class="projects-totalizers-container">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['up_votes']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Up votes' }}</p>
        </div>
        <div>
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['down_votes']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Down votes' }}</p>
        </div>
      </b-row>
    </div>

    <div v-if="!is_desktop">
      <div class="card col-12">
        <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['count_questions']}}</p>
        <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Question Asked' }}</p>
      </div>
      <b-row>
        <div class="col-6 card">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['pinned_responses']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Pinned responses' }}</p>
        </div>
        <div class="col-6 card">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['notes_saved']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Notes saved' }}</p>
        </div>
      </b-row>
      <b-row>
        <div class="col-6 card">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['up_votes']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Up votes' }}</p>
        </div>
        <div class="col-6 card">
          <p class="p-numbers projects-totalizers-value ta-center openSans-400 fz-27 mb-0 mt-3">{{questions.stats['down_votes']}}</p>
          <p class="projects-totalizers-label openSans-100 fz-16">{{ 'Down votes' }}</p>
        </div>
      </b-row>
    </div>


    <b-row class="mx-0 my-4">
      <span>Filter By Reaction:</span>
      <b-form-checkbox class="activity-filters"
                        v-model="allActivity"
                        >All activity</b-form-checkbox>
      <b-form-checkbox class="activity-filters"
                        v-model="allSearches"
                        >All searches</b-form-checkbox>
      <b-form-checkbox class="activity-filters" v-for="nameFilter in allFilters"
                        :key="Object.keys(nameFilter)[0]"
                        :value="Object.keys(nameFilter)[0]"
                        v-model="selectedFilters"
                        >{{nameFilter[Object.keys(nameFilter)[0]]}}
      </b-form-checkbox>
    </b-row>

    <div class="carousel">
      <history-card v-for="question in filteredQuestions" :key="question.id"
                    :question="question"></history-card>
    </div>

  </div>
</template>

<script>
import HistoryService from "@/modules/userHistory/services/HistoryService";
import HistoryCard from "@/modules/userHistory/components/HistoryCard";
export default {
name: "History",
  components: {HistoryCard},
  data() {
    return {
      allActivity: true,
      allSearches: true,
      allFilters: [{'have_comment': 'Feedback', check: true},
                   {'have_pinned': 'Pin', check: true},
                   {'have_notes': 'Notes', check: true},
                   {'have_thumbs_up': 'Up Votes', check: true},
                   {'have_thumbs_down': 'Down Votes', check: true}],
      selectedFilters: [],
      allSubFilters: ['have_comment','have_pinned','have_notes',
                      'have_thumbs_up','have_thumbs_down'],
      questions: {},
      filteredQuestions: [],
      filters: {
        since: '7',
        descending: -1
      },
      options: {
        since: [
          { value: '9999', text: 'myApps.qaPairs.filters.since.anyTime' },
          { value: '1', text: 'myApps.qaPairs.filters.since.today' },
          { value: '7', text: 'myApps.qaPairs.filters.since.pastWeek' },
          { value: '14', text: 'myApps.qaPairs.filters.since.pastTwoWeeks' },
          { value: '30', text: 'myApps.qaPairs.filters.since.pastMonth' },
          { value: '365', text: 'myApps.qaPairs.filters.since.pastYear' }
        ],
        descending: -1
      }
    }
  },
  methods: {
    getHistory() {
      this.selectedFilters = []
      let filters = this.filters
      filters.descending = this.filters.descending === -1
      filters.since = filters.since*1

      HistoryService.getHistory(filters).then( response => {
        let data = response.data
        this.questions = data
        this.filteredQuestions = this.questions['user_questions']

        this.allFilters.forEach(filter => {
          this.selectedFilters.push(Object.keys(filter)[0])
        })
      })
    },
    applyFilter() {
      if (this.allActivity) {
       this.filteredQuestions = this.questions['user_questions']
      } else {
        if (this.allSearches) {
          let filteredQuestions = this.questions['user_questions'].filter(
            question => question['question'] !== undefined)
          this.filteredQuestions = Array.from(new Set(filteredQuestions))
        } else {
          // check subfilters
          this.filteredQuestions = this.questions['user_questions'].filter(
            question => this.filterQuestion(question))
        }
      }
    },
    filterQuestion(question) {
      let filteringNote = this.selectedFilters.indexOf('have_notes') > -1
      let filteringComment = this.selectedFilters.indexOf('have_comment') > -1
      let filteringPinned = this.selectedFilters.indexOf('have_pinned') > -1
      let filteringThumbUp = this.selectedFilters.indexOf('have_thumbs_up') > -1
      let filteringThumbDown = this.selectedFilters.indexOf('have_thumbs_down') > -1

      if (filteringNote) {
        if (question['have_notes'] === true) {
          return true
        }
      }
      if (filteringComment) {
        if (question['have_comment'] === true) {
          return true
        }
      }
      if (filteringPinned) {
        if (question['have_pinned'] === true) {
          return true
        }
      }
      if (filteringThumbUp) {
        if (question['have_thumbs_up'] === true) {
          return true
        }
      }
      if (filteringThumbDown) {
        if (question['have_thumbs_down'] === true) {
          return true
        }
      }
    },
    filterAll() {
      if(this.allActivity) {
        this.selectedFilters = this.allSubFilters
      } else {
        this.selectedFilters = []
      }
    },
    filterAllSearches() {
      if(this.allSearches) {
        this.selectedFilters = this.allSubFilters
      } else {
        this.selectedFilters = []
      }
    }
  },
  computed: {
    is_desktop: function() {
      const isDesktop = window.matchMedia("only screen and (min-width: 1024px)")
      return isDesktop.matches ? true : false
    }
  },
  watch: {
   selectedFilters() {
     this.applyFilter()
   },
   allActivity() {
     this.filterAll()
   },
   allSearches() {
     this.filterAllSearches()
   }
  },
  created() {
    this.getHistory()
  }
}
</script>

<style lang="scss" scoped>

@media (min-width: 768px) and (max-width: 1023px) {


}

@media (min-width: 320px) and (max-width: 1024px) {

  .container-activity {
    width: 100% !important;
    padding: 12px !important;
  }

  .header-second-block-activity {
    width: 100%;
  }

  .activity-filters {
    width: 100%;
    text-align: left;
    margin: 4px 0px;
  }
}



.activity-filters {
  margin-left: 20px;
}

.counter-activity {
  padding: 16px 20px;

  p {
    margin-bottom: 0;
  }
}

.header-activity {
  margin-bottom: 20px;
}

.container-activity {
  width: calc(100% - 77px);
  padding: 20px 32px;
}

.history-totalizers-container {
    margin-right: 63px;
}
</style>