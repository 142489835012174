<template>
  <b-card no-body >
          <b-card-header header-tag="header">
            <b-row class="justify-content-between align-center">
              <div :class="[
              is_mobile && question['actions'].length == 0 ? 'mobile-activity-card-100' : '',
              is_mobile && (question['actions'].length > 0 && question['action_description'] === undefined) ? 'mobile-activity-card-70': '',
              ]">
                <div class="text-left myproj-projectname my-0" :class="is_mobile ? 'pl-0': ''" v-if="question['action']" @click="ask" >
                  {{ question['action'] }} {{'action_description' in question ? ':' : ''}} {{ question['action_description']}}
                </div>
                <div class="text-left myproj-projectname my-0" v-else :class="is_mobile ? 'pl-0' : ''" @click="ask" >
                  <span>{{ 'Searched: ' + question.question }}</span>
                  <span class="activity-time d-block txt-black fz-14 openSans-400" :id="'tooltip-target_'+question.id">
                    {{ question['time_ago'] }}
                  </span>
                  <b-tooltip :target="'tooltip-target_'+question.id" triggers="hover" placement="topright">
                    {{question['timestamp_ago']}}
                  </b-tooltip>
                  <b-row v-if="question.tags[0] " class="ml-0 mt-2">
                    <span v-for="tag in question.tags" :key="tag" class="pill-tags openSans-600 fz-12" >{{tag}}</span>
                  </b-row>
                </div>
              </div>
              <div>
                <img class="cursor-pointer knowledge-checkmark icono" v-if="question['have_comment']" src="/assets/images/user_activity_feedback.svg"/>
                <img class="cursor-pointer knowledge-checkmark icono" v-if="question['have_notes']" src="/assets/images/user_activity_notes.svg"/>
                <img class="cursor-pointer knowledge-checkmark icono" v-if="question['have_thumbs_down']" src="/assets/images/user_activity_downvote.svg"/>
                <img class="cursor-pointer knowledge-checkmark icono" v-if="question['have_thumbs_up']" src="/assets/images/user_activity_upvote.svg"/>
                <img class="cursor-pointer knowledge-checkmark icono" v-if="question['have_pinned']" src="/assets/images/user_activity_pinned.svg"/>
                <img class="cursor-pointer"  v-if="(question['actions'].length > 0) && (question['action_description'] === undefined)" 
                v-b-toggle="'collapse_'+question.id"  src="/assets/images/chevron-down.svg"/>
              </div>
            </b-row>
            <!--     ids dynamic       -->
          </b-card-header>
          <b-collapse :id="'collapse_'+question.id" visible accordion="my-accordion" >
        <b-card  class="ta-left">
          <b-card-text><b>{{question.question}}</b></b-card-text>

          <b-card-text v-for="action in question['actions']" :key="action.id">

            <div v-if="action['passage_id']">
              <b-card-text>
                <span style="margin-right:7px" class="toggleable-icon pin-icon" :class="{'pin-icon-pressed': action['pinned'] }"></span>
                Library Response
              </b-card-text>
              <markdown-to-html :source="action['passage_text']"></markdown-to-html>
            </div>
            <div v-else-if="action['input_id']">
              <b-card-text>
                <span style="margin-right:7px" class="toggleable-icon pin-icon" :class="{'pin-icon-pressed': action['pinned'] }"></span>
                {{action['input_text']}}
              </b-card-text>
              Q & A Response
               <div v-if="action['verifier']" class="knowledge-content-container ta-left d-block">
                  <img class="cursor-pointer knowledge-checkmark" src="/assets/images/icon_checkmark.svg"/>
                  <h3 class="openSans-100 fz-11">Verified <span> by {{ action['userid'] }} </span><span>• {{ action['verify_time'] }}</span></h3>
                </div>
              <markdown-to-html :source="action['response_text']"></markdown-to-html>

            </div>

            <div v-else-if="action['thumbs_up']  ||  action['thumbs_down']">
              <img  class="cursor-pointer margin-thumbs-up" v-if="action['thumbs_up']" :class="{'thumbs-up-click': highlightThumbsUp}" :src="`/assets/images/user_activity_upvote.svg`"/>
              <img  class="cursor-pointer" v-if="action['thumbs_down']" :class="{'thumbs-frown-click': highlightThumbsDown}" :src="`/assets/images/user_activity_downvote.svg`"/>
            </div>

            <div v-else>{{action['action_type']}}</div>

            <b-tabs class="col-12" content-class="mt-3" v-if="action['comment'] || action['note']">
              <b-tab v-if="action['comment']">
                <template v-slot:title>
                  <div>
                    <span>Feedback</span>
                  </div>
                </template>
                <b-card>
                  <b-card-text>{{action['comment']['text']}}</b-card-text>
                  <p class="library-document-update-span openSans-400 fz-13 ml-0">{{action['comment']['time_ago']}}</p>
                </b-card>
              </b-tab>
              <b-tab v-if="action['note']">
                <template v-slot:title>
                  <div>
                    <span>Note</span>
                  </div>
                </template>
                <b-card>
                  <b-card-text>{{action['note']['note']}}</b-card-text>
                  <p class="library-document-update-span openSans-400 fz-13 ml-0">{{action['note']['time_ago']}}</p>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card-text>

        </b-card>
      </b-collapse>

          </b-card>
</template>

<script>
import MarkdownToHtml from "@/modules/common/components/MarkdownToHtml";
export default {
name: "historyCard",
  components: {MarkdownToHtml},
  props: {
    question: Object
  },
  data () {
    return {
      highlightThumbsUp: true,
      highlightThumbsDown: true
    }
  },
  methods: {
    ask() {
      console.log('go to project')
      // function ask(button, logid) {
      // var url = '/ask/{{ filter["bot"] }}?question=' + button.innerText + '&from_notification=Y&logid='+ logid;
      // let url = '/ask/{{ filter["bot"] }}?question=' + button.innerText + '&from_notification=Y&logid='+ logid;
      // window.open(url, '_blank');
    }
  },
  computed: {
    is_mobile: function() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>

@media (min-width: 320px) and (max-width: 767px) {
  
  .myproj-projectname {
    margin: 0px;
  }

}

  .mobile-activity-card-85 {
    width: 85%;
  }

  .mobile-activity-card-70 {
    width: 70%;
  }

  .mobile-activity-card-100 {
    width: 100%;
  }

  .activity-time {
    margin-top: 5px;
    margin-bottom: 0px !important
  }

  .thumbs-up-click {
    filter: invert(41%) sepia(80%) saturate(4758%) hue-rotate(199deg) brightness(100%) contrast(110%);
  }

  .thumbs-frown-click {
    filter: invert(41%) sepia(80%) saturate(4758%) hue-rotate(199deg) brightness(100%) contrast(110%);
  }

  .margin-thumbs-up {
    margin-right: 16px;
  }

  .pin-icon {
  background-image: url('/assets/images/pin-icon.svg');
}

  .pin-icon-pressed {
    background-image: url('/assets/images/pin-icon-pressed.svg');
  }

  .toggleable-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: cover;
    vertical-align: middle;
    cursor: pointer;
  }

  .icono {
    border-radius: 40px;
    background-color: #afafaf;
    padding: 4px;
  }

  .knowledge-checkmark {
   margin: 0px;
  }
</style>