import Repository from "@/modules/common/utils/Repository";
import store from "@/store";


export default {
  getHistory(extraFilters = {}) {
    extraFilters['bot_name'] = store.getters.getInstance
    extraFilters['user_id'] = store.getters.getCurrentUser.userid
    return Repository.dbService.get('/user/user-history', {
      params: extraFilters
    })
  }
}